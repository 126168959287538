/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import "./ThankYouPopup.scss";
import { useJoinISR } from "../../../../hooks/useJoinISR/useJoinISR";

const ThankYouPopup = ({ isModalOpen, setIsModalOpen }) => {
  const { thankYouPopup } = useJoinISR();
  if (isModalOpen)
    return (
      <Modal
        id="myModal"
        className="thank-you-popup"
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
      >
        <Modal.Body className="popup-content">
          <span className="close" onClick={() => setIsModalOpen(false)}>
            &times;
          </span>
          <div
            className="col-md-8 content-wrapper"
            dangerouslySetInnerHTML={{
              __html: thankYouPopup,
            }}
          />
        </Modal.Body>
      </Modal>
    );
  return null;
};

ThankYouPopup.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

export default ThankYouPopup;
