/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import create from "zustand";
import produce from "immer";

const useStore = create((set) => ({
  formStore: {
    currentQuestion: null,
    btnLabel: "Next",
  },
  setFormStore: (input) => set((state) => ({ formStore: input })),
}));

const limit = (config) => (set, get, api) =>
  config(
    (args) => {
      set(args);
      if (get().progress >= 100) {
        return set((state) => (state.progress = 100));
      }
      if (get().progress < 0) {
        return set((state) => (state.progress = 0));
      }
    },
    get,
    api
  );

// Turn the set method into an immer proxy
const immer = (config) => (set, get, api) =>
  config((fn) => set(produce(fn)), get, api);

const useProgressStore = create(
  limit(
    immer((set) => ({
      progress: 0,
      setProgress: (input) => set((state) => ({ progress: input })),
    }))
  )
);

export { useStore, useProgressStore };
