import * as Yup from "yup";

const validationSchema = Yup.object({
  fits_most: Yup.string().nullable().required("Required"),
  crisis_interest_type: Yup.object().nullable().required("Required"),
  // crisis_interest_category: Yup.object().nullable().required("Required"),
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  institution: Yup.string().required("Required"),
  title: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email adress").required("Required"),
  country: Yup.object().nullable().required("Required"),
  street1: Yup.string(),
  street2: Yup.string(),
  city: Yup.string(),
  state_code: Yup.lazy((value) =>
    typeof value === "object"
      ? Yup.object() // typeError is necessary here, otherwise we get a bad-looking yup error
      : Yup.string()
  ),
  province: Yup.string(),
  zip: Yup.string(),
  country_code: Yup.string(),
  phone: Yup.string(),
  nyas_member: Yup.string(),
  newsletter: Yup.string().required("Required"),
  recaptcha: Yup.string()
    .nullable()
    .required("Please complete the CAPTCHA validation"),
});

export default validationSchema;
