import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import FormHeader from "../components/JoinIsr/components/FormHeader/FormHeader";
import Questionnaire from "../components/JoinIsr/components/Questionaire/Questionaire";
import ClassicForm from "../components/JoinIsr/components/ClassicForm/ClassicForm";

const JoinISR = () => {
  return (
    <Layout>
      <Header />
      <section>
        <FormHeader />
        {/* <IsrProgressBar /> */}
        {/* <Questionnaire /> */}
        <ClassicForm />
      </section>
      <Footer />
    </Layout>
  );
};

export default JoinISR;
