import React, { useState } from "react";
import { Field, FormikProvider, useFormik } from "formik";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import statesData from "../../../../assets/json/statesUS.json";
import countriesData from "../../../../assets/json/countries.json";
import Spacer from "../../../Spacer/Spacer";
import validationSchema from "./validationSchema";
import IsrInput from "../../../IsrInput/IsrInput";
import IsrButton from "../../../IsrButton/IsrButton";
import ThankYouPopup from "../ThankYouPopup/ThankYouPopup";
import "./ClassicForm.scss";

const ClassicForm = () => {
  const [isThankYouPopupOpen, setIsThankYouPopupOpen] = useState(false);
  const getConfig = {
    headers: {
      auth_secret: process.env.GATSBY_AUTH_SECRET,
    },
  };

  const joinUsForm = useFormik({
    initialValues: {
      fits_most: null,
      crisis_interest_type: { label: "Wildfires", value: 1 },
      crisis_interest_category: null,
      best_describes_industry: null,
      research_area: null,
      first_name: "",
      last_name: "",
      institution: "",
      title: "",
      email: "",
      country: null,
      street1: "",
      street2: "",
      city: "",
      state_code: "",
      province: "",
      zip: "",
      phone: "",
      nyas_member: "",
      newsletter: false,
      recaptcha: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      axios
        .post(process.env.GATSBY_SEND_EMAIL_ENDPOINT, values, getConfig)
        .then((response) => {
          if (response.status === 200) setIsThankYouPopupOpen(true);
          joinUsForm.setSubmitting(false);
          joinUsForm.resetForm();
        })
        .catch((error) => {
          console.log(error);
          joinUsForm.setSubmitting(false);
          joinUsForm.resetForm();
        });
    },
  });

  const countries = countriesData.map((country) => {
    return { label: country.name, value: country.name };
  });

  const statesUS = statesData.map((state) => {
    return { label: state.name, value: state.name };
  });

  // this most likely will change in the future
  const optionsForScientist = [
    { label: "Academic / Research Institution", value: "academic/research" },
    { label: "Corporate", value: "Corporate" },
    { label: "Government", value: "Government" },
    { label: "Non-Profit", value: "Non-Profit" },
    { label: "Other", value: "Other" },
  ];
  const optionsForOrganization = [
    { label: "Academic / Research Institution", value: "academic/research" },
    { label: "Corporate", value: "Corporate" },
    { label: "Government", value: "Government" },
    { label: "Non-Profit", value: "Non-Profit" },
    { label: "Other", value: "Other" },
  ];

  const optionsForResearchInstitution = [
    {
      label: "Life Sciences",
      options: [
        { label: "Ecology", value: "Ecology" },
        { label: "Agriculture Sciences", value: "Agriculture Sciences" },
        { label: "Medicine", value: "Medicine" },
        { label: "Other Life Sciences", value: "Other Life Sciences" },
      ],
    },
    {
      label: "Physical Sciences & Sustainability",
      options: [
        {
          label: "Chemistry & Chemical Engineering",
          value: "Chemistry & Chemical Engineering",
        },
        { label: "Climate Science", value: "Fire Science" },
        { label: "Earth Science", value: "Earth Science" },
        { label: "Forestry", value: "Forestry" },
        { label: "Soil Science", value: "Soil Science" },
        {
          label: "Sustainability & Environmental Policy",
          value: "Sustainability & Environmental Policy",
        },
        { label: "Engineering", value: "Engineering" },
        { label: "Artificial Intelligence", value: "Artificial Intelligence" },
        { label: "Data Science", value: "Data Science" },
        { label: "Information Science", value: "Information Science" },
        { label: "Other Physical Sciences", value: "Other Physical Sciences" },
      ],
    },
    {
      label: "Behavioral Sciences",
      options: [
        {
          label: "Mental Health & Wellness",
          value: "Mental Health & Wellness",
        },
        {
          label: "Psychology & Behavioral Sciences",
          value: "Psychology & Behavioral Sciences",
        },
        {
          label: "Other Behavioral Sciences",
          value: "Other Behavioral Sciences",
        },
      ],
    },
    {
      label: "Social Sciences",
      options: [
        { label: "Science Policy", value: "Science Policy" },
        { label: "Sociology", value: "Sociology" },
        {
          label: "Public Health & Epidemiology",
          value: "Public Health & Epidemiology",
        },
        { label: "Economics", value: "Economics" },
        { label: "Other Social Sciences", value: "Other Social Sciences" },
      ],
    },
  ];

  return (
    <section className="join-irs">
      <div className="container">
        <FormikProvider value={joinUsForm}>
          <form onSubmit={joinUsForm.handleSubmit} autoComplete="off">
            <Spacer size={30} mobileSize={50} />
            <h2>Crisis Interest</h2>
            <Spacer size={20} mobileSize={20} />
            <div className="row">
              <div className="col-lg-3 col-xl-4" />
              <div className="col-lg-6 col-xl-4">
                <Field
                  name="crisis_interest_type"
                  id="crisis_interest_type"
                  variant="select"
                  classNamePrefix="isr"
                  component={IsrInput}
                  placeholder="Select crisis type"
                  options={[{ label: "Wildfires", value: 1 }]}
                />
                {/* <Spacer size={30} mobileSize={10} /> */}
                {/* <Field
                  name="crisis_interest_category"
                  id="crisis_interest_category"
                  variant="select"
                  classNamePrefix="isr"
                  component={IsrInput}
                  placeholder="Research category"
                  options={[
                    { label: "Category1", value: 1 },
                    { label: "Category2", value: 2 },
                    { label: "Category3", value: 3 },
                  ]}
                /> */}
              </div>
              <div className="col-lg-3 col-xl-4" />
            </div>
            <Spacer size={70} mobileSize={40} />
            <hr size={3} className="divider grey" />
            <Spacer size={70} mobileSize={40} />
            <h2>Please indicate which one fits you best?</h2>
            <Spacer size={30} mobileSize={30} />
            <Field
              name="fits_most"
              id="fits_most_option1"
              value="Scientist"
              variant="radio"
              component={IsrInput}
              label="Scientist looking to join community / submit proposals"
            />
            <Spacer size={30} mobileSize={30} />
            <Field
              name="fits_most"
              id="fits_most_option2"
              value="Organization"
              variant="radio"
              component={IsrInput}
              label="Organization who wants to support the ISR through providing funding or resources, or is interested in collaboration"
            />
            <Spacer size={30} mobileSize={30} />
            <Field
              name="fits_most"
              id="fits_most_option3"
              value="Development"
              variant="radio"
              component={IsrInput}
              label="Individual who wants to support the ISR through providing funding or resources, or is interested in collaboration"
            />
            <Spacer size={30} mobileSize={30} />
            <Field
              name="fits_most"
              id="fits_most_option4"
              value="General"
              variant="radio"
              component={IsrInput}
              label="General interest: I want to stay updated and learn more"
            />
            {joinUsForm.touched.fits_most && joinUsForm.errors.fits_most ? (
              <p className="form-error-radio">
                <em>{joinUsForm.errors.fits_most}</em>
              </p>
            ) : null}
            {(joinUsForm.values.fits_most === "Scientist" ||
              joinUsForm.values.fits_most === "Organization") && (
              <>
                <Spacer size={70} mobileSize={40} />
                <hr size={3} className="divider grey" />
                <Spacer size={70} mobileSize={40} />
                <h2>Which best describes your industry?</h2>
                <Spacer size={20} mobileSize={20} />
                <div className="row">
                  <div className="col-md-3" />
                  <div className="col-md-6">
                    <Field
                      name="best_describes_industry"
                      id="best_describes_industry"
                      variant="select"
                      classNamePrefix="isr"
                      component={IsrInput}
                      placeholder="Select option"
                      options={
                        joinUsForm.values.fits_most === "Scientist"
                          ? optionsForScientist
                          : optionsForOrganization
                      }
                    />
                  </div>
                  <div className="col-md-3" />
                </div>
                {joinUsForm.values.best_describes_industry?.value ===
                  "academic/research" &&
                  joinUsForm.values.fits_most === "Scientist" && (
                    <>
                      <Spacer size={50} mobileSize={30} />
                      <h2>Please indicate your research area</h2>
                      <Spacer size={20} mobileSize={20} />
                      <div className="row">
                        <div className="col-md-3" />
                        <div className="col-md-6">
                          <Field
                            name="research_area"
                            id="research_area"
                            variant="select"
                            classNamePrefix="isr"
                            component={IsrInput}
                            placeholder="Select option"
                            options={optionsForResearchInstitution}
                          />
                        </div>
                        <div className="col-md-3" />
                      </div>
                    </>
                  )}
              </>
            )}
            <Spacer size={70} mobileSize={40} />
            <hr size={3} className="divider grey" />
            <Spacer size={70} mobileSize={40} />
            <div className="row">
              <div className="col-lg-6">
                <Field
                  name="first_name"
                  id="first_name"
                  component={IsrInput}
                  placeholder="First name"
                  autoComplete="off"
                  variant="text"
                />
              </div>
              <div className="d-lg-none">
                <Spacer size={30} mobileSize={10} />
              </div>
              <div className="col-lg-6">
                <Field
                  name="last_name"
                  id="last_name"
                  component={IsrInput}
                  placeholder="Last name"
                  autoComplete="off"
                  variant="text"
                />
              </div>
            </div>
            <Spacer size={30} mobileSize={10} />
            <div className="row">
              <div className="col-lg-6">
                <Field
                  name="institution"
                  id="institution"
                  component={IsrInput}
                  placeholder="Institution"
                  autoComplete="off"
                  variant="text"
                />
              </div>
              <div className="d-lg-none">
                <Spacer size={30} mobileSize={10} />
              </div>
              <div className="col-lg-6">
                <Field
                  name="title"
                  id="title"
                  component={IsrInput}
                  placeholder="Title"
                  autoComplete="off"
                  variant="text"
                />
              </div>
            </div>
            <Spacer size={30} mobileSize={10} />
            <div className="row">
              <div className="col-lg-6">
                <Field
                  name="email"
                  id="email"
                  component={IsrInput}
                  placeholder="Email address"
                  variant="email"
                />
              </div>
              <div className="d-lg-none">
                <Spacer size={30} mobileSize={10} />
              </div>
              <div className="col-lg-6">
                <Field
                  name="country"
                  id="country"
                  variant="select"
                  classNamePrefix="isr"
                  component={IsrInput}
                  placeholder="Country"
                  autoComplete="off"
                  options={countries}
                />
              </div>
            </div>
            <Spacer size={30} mobileSize={10} />
            <div className="row">
              <div className="col-lg-6">
                <Field
                  name="street1"
                  id="street1"
                  component={IsrInput}
                  placeholder="Street 1 (optional)"
                  autoComplete="off"
                  variant="text"
                />
              </div>
              <div className="d-lg-none">
                <Spacer size={30} mobileSize={10} />
              </div>
              <div className="col-lg-6">
                <Field
                  name="street2"
                  id="street2"
                  component={IsrInput}
                  placeholder="Street 2 (optional)"
                  autoComplete="off"
                  variant="text"
                />
              </div>
            </div>
            <Spacer size={30} mobileSize={10} />
            <div className="row">
              <div className="col-lg-6">
                <Field
                  name="city"
                  id="city"
                  component={IsrInput}
                  autoComplete="off"
                  placeholder="City (optional)"
                  variant="text"
                />
              </div>
              <div className="d-lg-none">
                <Spacer size={30} mobileSize={10} />
              </div>
              <div className="col-lg-6">
                {joinUsForm.values.country?.value === "United States" ? (
                  <Field
                    name="state_code"
                    id="state_code"
                    variant="select"
                    classNamePrefix="isr"
                    autoComplete="off"
                    component={IsrInput}
                    placeholder="State (optional)"
                    options={statesUS}
                  />
                ) : (
                  <Field
                    name="state_code"
                    id="state_code"
                    component={IsrInput}
                    autoComplete="off"
                    placeholder="State (optional)"
                    variant="text"
                  />
                )}
              </div>
            </div>
            <Spacer size={30} mobileSize={10} />
            <div className="row">
              <div className="col-lg-6">
                <Field
                  name="province"
                  id="province"
                  autoComplete="off"
                  component={IsrInput}
                  placeholder="Province (optional)"
                  variant="text"
                />
              </div>
              <div className="d-lg-none">
                <Spacer size={30} mobileSize={10} />
              </div>
              <div className="col-lg-6">
                <Field
                  name="zip"
                  id="zip"
                  component={IsrInput}
                  autoComplete="off"
                  placeholder="Zip (optional)"
                  variant="text"
                />
              </div>
            </div>
            <Spacer size={30} mobileSize={10} />
            <div className="row">
              <div className="d-lg-none">
                <Spacer size={30} mobileSize={10} />
              </div>
              <div className="col-lg-6">
                <Field
                  name="phone"
                  id="phone"
                  component={IsrInput}
                  autoComplete="off"
                  placeholder="Phone number (optional)"
                  variant="text"
                />
              </div>
            </div>
            <Spacer size={30} mobileSize={30} />
            <p>Are you a NYAS member?</p>
            <Field
              name="nyas_member"
              id="fnyas_member_option1"
              variant="radio"
              component={IsrInput}
              value="Yes"
              label="Yes"
            />
            <Spacer size={8} mobileSize={8} />
            <Field
              name="nyas_member"
              id="fnyas_member_option2"
              variant="radio"
              component={IsrInput}
              value="No"
              label="No"
            />
            {joinUsForm.touched.nyas_member && joinUsForm.errors.nyas_member ? (
              <p className="form-error-radio">
                <em>{joinUsForm.errors.nyas_member}</em>
              </p>
            ) : null}
            <Spacer size={10} mobileSize={20} />
            <hr size={3} className="divider grey" />
            <Spacer size={10} mobileSize={20} />
            <p>Please opt-in to receive NYAS marketing</p>
            <Field
              name="newsletter"
              id="newsletter"
              variant="checkbox"
              component={IsrInput}
              label="Yes"
            />
            <Spacer size={50} mobileSize={30} />
            <ReCAPTCHA
              name="recaptcha"
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
              onChange={(response) => {
                joinUsForm.setFieldValue("recaptcha", response);
              }}
            />
            {joinUsForm.errors.recaptcha && joinUsForm.touched.recaptcha && (
              <p className="form-error-radio">{joinUsForm.errors.recaptcha}</p>
            )}
            <Spacer size={50} mobileSize={30} />
            {joinUsForm.isSubmitting && (
              <div className="loader">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <p>Submitting...</p>
              </div>
            )}
            {!joinUsForm.isSubmitting && (
              <IsrButton variant="primary" type="submit" size="xl">
                Submit
              </IsrButton>
            )}
            <Spacer size={150} mobileSize={50} />
          </form>
        </FormikProvider>
      </div>
      <ThankYouPopup
        isModalOpen={isThankYouPopupOpen}
        setIsModalOpen={setIsThankYouPopupOpen}
      />
    </section>
  );
};

export default ClassicForm;
