import React from "react";
import { useJoinISR } from "../../../../hooks/useJoinISR/useJoinISR";
import Spacer from "../../../Spacer/Spacer";
import "./FormHeader.scss";

const FormHeader = () => {
  const { pageHeading } = useJoinISR();
  return (
    <div className="container form-header-join">
      <Spacer size={100} mobileSize={50} />
      <h1>{pageHeading}</h1>
      <hr size={3} className="divider" />
    </div>
  );
};

export default FormHeader;
