import { useStaticQuery, graphql } from "gatsby";

export const useJoinISR = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Join Isr" } }) {
        joinIsr {
          thankYouPopup
          pageHeading
        }
      }
    }
  `);
  return data.wpPage.joinIsr;
};
